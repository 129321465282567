import * as types from '../constants/StatusActionTypes'

function updateStatus(status){
    return {
        type: types.STATUS_UPDATED,
        status: status
    }
}

function setEvents(date, events){
    return {
        type: types.SET_EVENTS,
        date: date,
        events: events
    }
}

function setSummary(date, data){
    return {
        type: types.SET_SUMMARY,
        date: date,
        summary: data
    }
}

export function setUserDetails(data){
    return {
        type: types.SET_USER_DETAILS,
        data: data
    }
}

export function setHolidaySummary(data){
    return {
        type: types.SET_HOLIDAYS_SUMMARY,
        data: data
    }
}

export function listenForStatusupdates(firebase, date){
	return function(dispatch){
        return (new Promise((resolve, reject) => {				
				(async () => {
                    let user = firebase.auth().currentUser;
                    let firestore = firebase.firestore();

                    let listener = await firestore.collection('users').doc(user.email).collection('status').doc('current').onSnapshot((document) => {
                        dispatch(updateStatus(document.data()));
                    });

                    let listener2 = await firestore.collection('users').doc(user.email).collection('by-date').doc(date).collection('events').onSnapshot((querySnapshot) => {
                        var eventlist = [];
                        querySnapshot.forEach(function(doc) {
                            eventlist.push(doc.data());
                        });

                        eventlist.sort((a, b) => b.timestamp - a.timestamp);

                        dispatch(setEvents(date, eventlist));
                    });

                    let listener3 = await firestore.collection('users').doc(user.email).collection('by-date').doc(date).onSnapshot((document) => {
                        console.log(document.data());

                        dispatch(setSummary(document.id, document.data()));
                    });

                    await firestore.collection('users').doc(user.email).onSnapshot((document) => {
                        dispatch(setUserDetails(document.data()));
                    });

                    await firestore.collection('users').doc(user.email).collection('holidays-by-year').doc(String((new Date()).getFullYear())).onSnapshot((document) => {
                        if(document.exists){
                            dispatch(setHolidaySummary(document.data()));
                        }
                    });

					resolve();
				})();
			}));
	}
}

export function changeStatus(firebase, status){
    return function(dispatch){
        dispatch({
            'type': types.START_CHANGE_STATUS
        });

        let user = firebase.auth().currentUser;
        let firestore = firebase.firestore();

        firestore.collection("users").doc(user.email).collection('status').doc('current').set({
                status: status,
                changed: Date.now()
            }).then(function() {
                dispatch({
                    'type': types.DONE_CHANGE_STATUS
                });
            });
    }
}
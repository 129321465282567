import React from 'react'
import {connect} from 'react-redux'
import * as actionCreators from '../actions/RouterActions'
import matchPath from '../utils/matchPath'

function mapStateToProps (state) {
	return {
		currentRoute: state.router
	}
}

class RouteComponent extends React.Component {
	//mixins: [];
		
	render() {
		var match = matchPath(this.props.currentRoute.pathname, {
			path: this.props.path,
			exact: this.props.exact
		});
        
		if(match){
			var props = {match};
						
			if (this.props.component) return React.createElement(this.props.component, props);
		}
		
		return null;
	}
}

export const Route = connect(
  mapStateToProps,
  actionCreators
)(RouteComponent)
import React, {useEffect} from 'react';
import {useFirebase} from 'react-redux-firebase'
import {useSelector, useDispatch} from 'react-redux'
import * as StatusActions from '../actions/StatusActions'
import {TimeFromNow} from './TimeFromNow'
import {TimeClock} from './TimeClock';

export const Status = function(props) {
    const firebase = useFirebase();
    const dispatch = useDispatch();

    const status = useSelector((state) => state.status.get('current').toJS());
    const is_changing = useSelector((state) => state.status.get('is_changing'));
    const summary = useSelector((state) => {
        if(!state.status.hasIn(['summary', props.date]))return {};
        return state.status.getIn(['summary', props.date]).toJS();
    })

    useEffect(() => {
        console.log('Register listener for ', props.date);
        dispatch(StatusActions.listenForStatusupdates(firebase, props.date));
    }, [props.date]);

    return (
        <div className="status">
            <TimeClock summary={summary} status={status} />

            <div className={["status-indicator", status.status].join(' ')}>
            {
                (status.status === 'present')?
                    <div>
                        <div className="status-caption">
                            <div className="intro">Dein aktueller Status ist:</div>
                            <div className="statusname">anwesend</div>
                        </div>
                        <button className={is_changing?'present disabled':'present'} onClick={(e) => {
                            dispatch(StatusActions.changeStatus(firebase, 'absent'));
                        }}>Auf Abwesend wechseln</button>
                    </div>
                :
                    <div>
                        <div className="status-caption">
                            <div className="intro">Dein aktueller Status ist:</div>
                            <div className="statusname">abwesend</div>
                        </div>
                        <button className={is_changing?'absent disabled':'absent'} onClick={(e) => {
                            dispatch(StatusActions.changeStatus(firebase, 'present'));
                        }}>Auf Anwesend wechseln</button>
                    </div>
            }
            </div>
        </div>
    )
}
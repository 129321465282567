import React, { useState, useEffect } from 'react';
import {Duration} from './Duration';
import moment from 'moment';

export const TimeClock = function(props){
    let [timeWorkedToday, setTimeWorkedToday] = useState(0);
    let [sum, setSum] = useState(0);

    let summary = props.summary;
    let status = props.status;

    const carry = (typeof summary.carry !== 'undefined')?summary.carry:0;
    const targetTime = (typeof summary.target_time !== 'undefined')?summary.target_time:0;

    function calculateTime(){
        let sum = 0;
        sum += carry;
        sum += targetTime;

        let tToday = 0;

        if(typeof summary.duration !== 'undefined')
            tToday += summary.duration;

        if(typeof status !== 'undefined' && status.status === 'present'){
            //Present since
            let timeFromPresentStart = moment().diff(status.changed);
            tToday += timeFromPresentStart / 1000;
        }

        sum += tToday;
        setSum(sum);
        setTimeWorkedToday(tToday);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            calculateTime();
        }, 1000);
        return () => clearInterval(interval);

    }, [summary.carry, summary.target_time, summary.duration, status.changed, status.status]);

    return <div className={["timeclock", (typeof status._dirty !== 'undefined' && status._dirty)?'dirty':''].join(' ')}>
            <div className="caption-target-time">Heutige Soll-Arbeitszeit</div>
            <div className="target-time"><Duration duration={targetTime} /></div>
            <div className="caption-carry">Übertrag vom Vortag</div>
            <div className="carry"><Duration duration={carry} /></div>
            <div className="caption-today">Arbeitszeit Heute</div>
            <div className="today"><Duration duration={timeWorkedToday} />
                {
                    (typeof status._dirty !== 'undefined' && status._dirty)?
                        <div className="loader inline dark"></div>
                    :null
                }
            </div>
            <div className="caption-total">Gesamtzeit</div>
            <div className="total"><Duration duration={sum} /></div>
        </div>
}
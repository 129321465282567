import * as types from '../constants/AdminActionTypes'
const moment = require('moment');

function receivedUsers(users){
    return {
        type: types.RECEIVED_USERS,
        users: users
    }
}

export function loadUsers(firebase){
	return function(dispatch){
        dispatch({
            type: types.REQUEST_USERS
        })
        return (new Promise((resolve, reject) => {				
				(async () => {
                    let user = firebase.auth().currentUser;
                    let firestore = firebase.firestore();

                    try {
                        var listener = await firestore.collection('users').onSnapshot((userSnapshots) => {
                            var users = {};
                            userSnapshots.forEach(doc => {
                                users[doc.id] = doc.data();
                            })

                            dispatch(receivedUsers(users)); 
                        });
                    }catch(e){
                        if(typeof e.code === 'undefined' || e.code !== 'permission-denied')
                            throw(e);
                        else
                            dispatch({
                                type: types.NOT_ALLOWED
                            })
                    }

                    dispatch({
                        type: types.DONE_REGISTER_USER_LISTENER,
                        listener: listener
                    })

					resolve();
				})();
			}));
	}
}

export function selectUser(userId){
    return {
        type: types.USER_SELECTED,
        userId: userId
    }
}

export function setWeekdayConfig(dayIdx, config){
    return {
        type: types.SET_WEEKDAY_CONFIG,
        day: dayIdx,
        config: config
    }
}

export function setUserConfig(key, value){
    return {
        type: types.SET_USER_CONFIG,
        key: key,
        value: value
    }
}

export function storeUserConfig(firebase, userId, config){
	return function(dispatch){
        dispatch({
            type: types.START_STORE_USER_CONFIG
        })

        return (new Promise((resolve, reject) => {				
				(async () => {
                    let user = firebase.auth().currentUser;
                    let firestore = firebase.firestore();

                    try {
                        let userSnapshots = await firestore.collection('users').doc(userId).collection('events').add({
                            type: 'set_user_config',
                            timestamp: Date.now(),
                            user: user.email,
                            data: config
                        });

                        dispatch({
                            type: types.DONE_STORE_USER_CONFIG
                        })
                        
                    }catch(e){
                        throw(e);
                    }

					resolve();
				})();
			}));
	}
}

export function editDay(firebase, date, userId){
	return function(dispatch){
        dispatch({
            type: types.START_EDIT_DAY,
            userId: userId,
            date: date
        })

        return (new Promise((resolve, reject) => {				
				(async () => {
                    let firestore = firebase.firestore();

                    try {
                        let eventsSnapshots = await firestore.collection('users').doc(userId).collection('by-date').doc(date).collection('events').get();

                        var events = [];
                        eventsSnapshots.forEach((event) => {
                            events.push(event.data());
                        });

                        dispatch({
                            type: types.RECEIVED_EVENTS,
                            userId: userId,
                            date: date,
                            events: events
                        })
                        
                    }catch(e){
                        throw(e);
                    }

					resolve();
				})();
			}));
	}
}

export function setDayOff(firebase, date, userId, reason){
	return function(dispatch){
        dispatch({
            type: types.START_SET_DAY_OFF,
            userId: userId,
            date: date
        })

        return (new Promise((resolve, reject) => {				
				(async () => {
                    let user = firebase.auth().currentUser;
                    let firestore = firebase.firestore();

                    try {
                        let userSnapshots = await firestore.collection('users').doc(userId).collection('events').add({
                            type: 'set_day_off',
                            timestamp: Date.now(),
                            user: user.email,
                            data: {
                                date: date,
                                reason: reason
                            }
                        });

                        dispatch({
                            type: types.DONE_SET_DAY_OFF
                        })
                        
                    }catch(e){
                        throw(e);
                    }

					resolve();
				})();
			}));
	}
}

export function removeDayOff(firebase, date, userId){
	return function(dispatch){
        dispatch({
            type: types.START_SET_DAY_OFF,
            userId: userId,
            date: date
        })

        return (new Promise((resolve, reject) => {				
				(async () => {
                    let user = firebase.auth().currentUser;
                    let firestore = firebase.firestore();

                    try {
                        let userSnapshots = await firestore.collection('users').doc(userId).collection('events').add({
                            type: 'unset_day_off',
                            timestamp: Date.now(),
                            user: user.email,
                            data: {
                                date: date
                            }
                        });

                        dispatch({
                            type: types.DONE_SET_DAY_OFF
                        })
                        
                    }catch(e){
                        throw(e);
                    }

					resolve();
				})();
			}));
	}
}

export function setCorrectionTime(firebase, date, userId, time, reason){
	return function(dispatch){
        dispatch({
            type: types.START_SET_CORRECTION_TIME,
            userId: userId,
            date: date
        })

        return (new Promise((resolve, reject) => {				
				(async () => {
                    let user = firebase.auth().currentUser;
                    let firestore = firebase.firestore();

                    try {
                        firestore.collection('users').doc(userId).collection('by-date').doc(date).collection('events').add({
                            type: 'set_correction_time',
                            timestamp: Date.now(),
                            user: user.email,
                            duration: time,
                            reason: reason
                        });

                        dispatch({
                            type: types.DONE_SET_CORRECTION_TIME
                        })
                        
                    }catch(e){
                        throw(e);
                    }

					resolve();
				})();
			}));
	}
}

export function loadSummary(firebase, userId){
	return function(dispatch){
        dispatch({
            type: types.START_LOADING_SUMMARY,
            userId: userId
        })

        return (new Promise((resolve, reject) => {				
				(async () => {
                    let firestore = firebase.firestore();

                    var holidays = [];
                    let holidayDocs = await firestore.collection('users').doc(userId).collection('holidays-by-year').get();

                    if(typeof holidayDocs !== 'undefined' && holidayDocs !== null){
                        holidayDocs.forEach((doc) => {
                            holidays.push({
                                year: parseInt(doc.id),
                                ...doc.data()
                            });
                        });
                    }

                    holidays.sort((a, b) => a.year - b.year);

                    var time = {};
                    let today = moment().format('YYYY-MM-DD');

                    let docToday = await firestore.collection('users').doc(userId).collection('by-date').doc(today).get();

                    if(docToday.exists){
                        time = docToday.data();
                    }

                    dispatch({
                        type: types.DONE_LOADING_SUMMARY,
                        userId: userId,
                        holidays: holidays,
                        time: time
                    })

					resolve();
				})();
			}));
	}
}
import React, {useEffect} from 'react';
import {useFirebase} from 'react-redux-firebase'
import {useSelector, useDispatch} from 'react-redux'
import * as WeekActions from '../../actions/CalendarActions'
import * as AdminActions from '../../actions/AdminActions'
import * as DAY_OFF_REASONS from '../../constants/DayOffReasons';
import {Duration} from '../Duration';

const moment = require('moment');

export const Day = function(props) {
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const dayIdx = {
        'so': '0',
        'mo': '1',
        'di': '2',
        'mi': '3',
        'do': '4',
        'fr': '5',
        'sa': '6'
    };

    moment.locale('de');
    const dateObj = moment(props.date);
    let dateStr = dateObj.format('YYYY-MM-DD');
    let weekday = dateObj.format('dd');

    let day = useSelector((state) => {
        if(!state.calendar.hasIn([props.userId, 'day', dateStr]))
            return null;
        
        return state.calendar.getIn([props.userId, 'day', dateStr]).toJS();
    })

    let workday = useSelector((state) => {
        const userStateObj = props.admin?state.admin.getIn(['users', props.userId]):state.user;

        if(!userStateObj.has('workdays'))return false;
        if(!userStateObj.hasIn(['workdays', dayIdx[weekday.toLowerCase()]]))return false;
        let workdayObj = userStateObj.getIn(['workdays', dayIdx[weekday.toLowerCase()]]);

        if(!workdayObj.has('active') || !workdayObj.get('active'))return false;
        if(!workdayObj.has('multiweek') || !workdayObj.get('multiweek'))return true;

        let nthWeek = workdayObj.has('nthWeek')?workdayObj.get('nthWeek'):1;
        let startsOn = workdayObj.has('startsOn')?workdayObj.get('startsOn'):dateObj.valueOf();
        let everyNth = workdayObj.has('everyNth')?workdayObj.get('everyNth').toJS():[0];
        let weeks = moment(startsOn).diff(dateObj, 'weeks');

        for(var nthIdx = 0; nthIdx < everyNth.length; nthIdx++){
            if((weeks + everyNth[nthIdx]) % nthWeek !== 0)continue;

            return true;
        }

        return false;
    });

    useEffect(() => {
        if(day === null || typeof day.listener === 'undefined'){
            dispatch(WeekActions.loadDate(firebase, dateObj.format('YYYY-MM-DD'), props.userId));
        }
    }, [props.date, props.userId]);

    return (
        <div className={[
            "day",
            props.admin?'editable':'',
            props.selected?'selected':'',
            dateObj.isSame(moment(), 'day')?'today':'',
            weekday.toLowerCase(),
            workday?'workday':'no-workday',
            (day !== null && typeof day['public-holiday'] !== 'undefined')?'public-holiday':'',
            (day !== null && typeof day['day-off'] !== 'undefined')?'day-off':''
        ].join(' ')} onClick={(e) => {
            if(!props.admin)return;

            dispatch(AdminActions.editDay(firebase, dateStr, props.userId));
        }}>
            <div className="date">
                <div className="weekday" style={{
                    position: 'relative'
                }}>
                    <div style={{
                        visibility: (typeof day === 'undefined' || day === null || (typeof day.loading !== 'undefined' && day.loading))?'hidden':'visible'
                    }}>{weekday}</div>
                    {
                    (typeof day === 'undefined' || day === null || (typeof day.loading !== 'undefined' && day.loading))?
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: '50%',
                                marginLeft: '-0.5em'
                            }}>
                                <div className="loader inline" />
                            </div>
                        :null
                    }
                </div>
                <div className="full-date">{dateObj.format('DD.MM.YYYY')}</div>
            </div>
            {
                (day !== null && typeof day['public-holiday'] !== 'undefined')?
                    <div className="public-holiday">
                        {day['public-holiday'].name}
                    </div>
                :null
            }
            {
                (day !== null && typeof day['day-off'] !== 'undefined')?
                    <div className="day-off">
                        {(typeof DAY_OFF_REASONS[day['day-off'].reason] === 'undefined')?day['day-off'].reason:DAY_OFF_REASONS[day['day-off'].reason]}
                    </div>
                :null
            }
            {
                (day !== null && typeof day['time'] !== 'undefined' && dateObj.isBefore(moment(), 'day') && typeof day['time']['duration'] !== 'undefined' && day['time']['duration'] !== 0)?
                    <div className="time">
                        <Duration duration={day['time']['duration']} />
                    </div>
                :null
            }
        </div>
    )
}
import * as types from '../constants/UserActionTypes'
import {env} from '../constants/Environment';
/*
export function start_auth(){
    return {
        type: types.START_AUTH
    }
}

export function start_login_with_provider(){
    return {
        type: types.START_LOGIN_WITH_PROVIDER
    }
}

async function signInAnonymously(firebase){
    return new Promise((resolve, reject) => {
        firebase.auth().signInAnonymously().catch(function(error) {            
            reject(error);
        });

        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                var isAnonymous = user.isAnonymous;
                var uid = user.uid;
                resolve(user);
            }
        });
    });
}*/

export function logout(firebase){
    firebase.logout();
}

export function loadUserDetails(firebase){
	return function(dispatch){
        return (new Promise((resolve, reject) => {				
				(async () => {
                    dispatch({
                        type: types.START_LOADING_DETAILS
                    });
                    
                    let user = firebase.auth().currentUser;
                    let firestore = firebase.firestore();

                    let details = await firestore.collection('users').doc(user.email).get();
                    if(details.exists){
                        dispatch({
                            type: types.DONE_LOADING_DETAILS,
                            data: details.data()
                        })
                    }else{
                        dispatch({
                            type: types.DONE_LOADING_DETAILS,
                            data: {}
                        })
                    }

					resolve();
				})();
			}));
	}
}

/*
export function login_with_provider(provider, firebase, currentState){
	return function(dispatch){
		dispatch(start_login_with_provider());
		
        return (new Promise((resolve, reject) => {				
				(async () => {
                    //Anonymen Benutzer erstellen
                    
                    try {
                        var anonymousUser = await signInAnonymously(firebase);
                    }catch(e){
                        alert('Es ist ein Fehler bei der Anmeldung aufgetreten (1)!');
                        console.log(e);
                        return;
                    }

                    if(anonymousUser === null){
                        alert('Es ist ein Fehler bei der Anmeldung aufgetreten (2)!');
                        return false;
                    }

                    let doc = firebase.firestore().collection('environments/' + env + '/users/' + anonymousUser.uid + '/private').doc('state');
                    await doc.set(currentState);

                    var provider = new firebase.auth.GoogleAuthProvider();
                    firebase.auth().currentUser.linkWithRedirect(provider);

					resolve();
				})();
			}));
	}
}

export function start_session_restore(){
    return {
        type: types.START_SESSION_RESTORE
    }
}

export function finish_session_restore(){
    return {
        type: types.FINISH_SESSION_RESTORE
    }
}

export function restore(data){
    return {
        type: 'RESTORE',
        data: data
    }
}

export function restore_session(firebase, credential){
	return function(dispatch){
		dispatch(start_session_restore());
		
        return (new Promise((resolve, reject) => {				
				(async () => {
                    var auth = firebase.auth();
                    let uid = auth.currentUser.uid;

                    let doc = firebase.firestore().collection('environments/' + env + '/users/' + uid + '/private').doc('state');
                    let session = await doc.get();

                    if(session.exists){
                        let sessionData = session.data();
                        dispatch(restore(sessionData));

                        await doc.delete();
                    }

                    if(typeof credential !== 'undefined' && credential !== null){
                        await auth.currentUser.delete(); //Anonymen Benutzer löschen
                        await auth.signInWithCredential(credential); //Als nicht anonymer Benutzer anmelden
                    }

                    await firebase.firestore().

                    dispatch(finish_session_restore());
                    resolve();
				})();
			}));
	}
}*/
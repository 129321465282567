import React from 'react';

export const Duration = function(props){
    const negative = props.duration < 0;

    let minutes = Math.floor(Math.abs(props.duration) / 60);
    let seconds = Math.abs(props.duration) - (minutes * 60);

    let hours = Math.floor(minutes / 60);
    minutes = minutes - (hours * 60);

    //console.log(props.duration, hours, minutes, seconds);

    return <div className={negative?'duration negative':'duration positive'}>
            {
                negative?
                    <span className="sign sign-minus">-</span>
                :
                    <span className="sign sign-plus">+</span>
            }
            <span className="hours">
            {
                hours
            }
            </span>
            :
            <span className="minutes">
            {
                String(minutes).padStart(2, '0')
            }
            </span>
            :
            <span className="seconds">
            {
                String(Math.round(seconds)).padStart(2, '0')
            }
            </span>
        </div>
}
import * as types from '../constants/RouterActionTypes'

const initialState = {
  pathname: '/',
  search: '',
  hash: '',
};

export default function(state = initialState, action){
  switch (action.type) {
    case types.LOCATION_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
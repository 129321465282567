import React, {useEffect} from 'react';
import {useFirebase} from 'react-redux-firebase'
import {useSelector, useDispatch} from 'react-redux'
import * as CalendarActions from '../../actions/CalendarActions'
const moment = require('moment');
import {Day} from './';

export const Calendar = function(props) {
    const firebase = useFirebase();
    const dispatch = useDispatch();

    useEffect(() => {
        //dispatch(CalendarActions.loadUserConfig());
    }, []);

    moment.locale('de');

    const date = useSelector((state) => {
        if(state.calendar.hasIn([props.userId, 'week']))
            return state.calendar.getIn([props.userId, 'week']);
        
        return Date.now();
    });
    
    let daysOfWeek = [];

    let current = moment(date).startOf('week');

    for(var dayIdx = 0; dayIdx < 7; dayIdx++){
        daysOfWeek.push(current.valueOf());
        current.add(1, 'days');
    }

    return (
        <div className="calendar">
            <div className="week">
            {
                daysOfWeek.map((day) => {
                    return (
                        <Day date={day} selected={props.selected !== 'undefined' && moment(props.selected).isSame(day)} key={day} userId={props.userId} admin={props.admin} />
                    )
                })
            }
            </div>
            <div className="footer">
                <button className="small secondary" onClick={(e) => {
                    dispatch(CalendarActions.setWeek(moment(date).add(-1, 'week').valueOf(), props.userId));
                }}>‹ vorherige</button>

                <input type="date" value={moment(date).format('YYYY-MM-DD')} onChange={(e) => {
                    dispatch(CalendarActions.setWeek(moment(e.target.value).valueOf(), props.userId));
                }} />

                <button className="small secondary" onClick={(e) => {
                    dispatch(CalendarActions.setWeek(moment(date).add(1, 'week').valueOf(), props.userId));
                }}>nächste ›</button>
            </div>
        </div>
    )
}
import React, {useEffect} from 'react';
const moment = require('moment');

export const TimeFromNow = function(props) {
    var durationToday = moment.duration(props.changed);

    return (
        <div>
            {
                String(durationToday.hours()).padStart(2, '0') + ':' + String(durationToday.minutes()).padStart(2, '0') + ':' + String(durationToday.seconds()).padStart(2, '0')
            }
        </div>
    )
}
import React, {useEffect} from 'react';
import {useFirebase} from 'react-redux-firebase'
import {useSelector, useDispatch} from 'react-redux'
import * as AdminActions from '../../actions/AdminActions'
import {Weekday} from './';

export const Configuration = function(props) {
    const dispatch = useDispatch();
    const firebase = useFirebase();
    const days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    const isDirty = useSelector((state) => {
        let selected = state.admin.get('selected');

         if(typeof selected === 'undefined' || selected === null)return true;
         if(state.admin.hasIn(['users', selected, '_dirty']) && state.admin.getIn(['users', selected, '_dirty']))return true;
         return false;
    });

    const userConfig = useSelector((state) => {
        let selected = state.admin.get('selected');

        if(typeof selected === 'undefined' || selected === null)return null;

        let userConfig = {
            weeklyWorkhours: 40,
            daysOfHolidayOnA5DayWeek: 30
        };
        
        if(state.admin.hasIn(['users', selected]) && state.admin.hasIn(['users', selected, 'config']))
            userConfig = {...userConfig, ...state.admin.getIn(['users', selected, 'config']).toJS()};

        if(state.admin.hasIn(['users', selected, 'configDraft']))
            userConfig = {...userConfig, ...state.admin.getIn(['users', selected, 'configDraft']).toJS()};

        return userConfig;
    });

    const user = useSelector((state) => {
        let selected = state.admin.get('selected');
        if(typeof selected === 'undefined' || selected === null)return null;

        let user = {
            daysPerWeek: 0,
            dailyWorkhours: 0,
            daysOfHoliday: 0
        };
        
        if(state.admin.hasIn(['users', selected]))
            user = {...user, ...state.admin.getIn(['users', selected]).toJS()};

        if(state.admin.hasIn(['users', selected, 'draft']))
            user = {...user, ...state.admin.getIn(['users', selected, 'draft']).toJS()};

        return user;
    });

    const userId = useSelector((state) => state.admin.get('selected'));

    const dataToStore = useSelector((state) => {
        let selected = state.admin.get('selected');
        if(typeof selected === 'undefined' || selected === null)return null;

        let userConfig = {
            weeklyWorkhours: null,
            daysOfHolidayOnA5DayWeek: null
        };

        var dataToStore = {};

        if(state.admin.hasIn(['users', selected]) && state.admin.hasIn(['users', selected, 'config']))
            userConfig = {...userConfig, ...state.admin.getIn(['users', selected, 'config']).toJS()};

        if(state.admin.hasIn(['users', selected, 'configDraft'])){
            let draft = state.admin.getIn(['users', selected, 'configDraft']).toJS();

            for(var key in draft)
                if(draft[key] !== userConfig[key]){
                    if(typeof dataToStore['config'] === 'undefined')
                        dataToStore['config'] = {};
                    dataToStore['config'][key] = draft[key];
                }
        }

        for(var dayIdx in days){
            let day = {
                active: false
            };

            if(state.admin.hasIn(['users', selected, 'workdays', String(dayIdx)]))
                day = state.admin.getIn(['users', selected, 'workdays', String(dayIdx)]).toJS();

            if(!state.admin.hasIn(['workdays', selected, 'draft', String(dayIdx)]))continue;

            let draft = {...day, ...state.admin.getIn(['workdays', selected, 'draft', String(dayIdx)]).toJS()};

            for(var key in draft){
                if(typeof day[key] === 'undefined' || draft[key] !== day[key]){
                    if(typeof dataToStore['workdays'] === 'undefined')
                        dataToStore['workdays'] = {};
                    dataToStore['workdays'][dayIdx] = draft;
                    if(typeof dataToStore['workdays'][dayIdx].draft !== 'undefined')
                        delete dataToStore['workdays'][dayIdx].draft;
                }
            }            
        }

        if(Object.keys(dataToStore).length === 0)return null;
        return dataToStore;
    });

    if(isDirty)
        return (
            <div className="loader" />
        )

    return (
        <div className="configuration">
            <div className="weekday-list">
                <h4>Arbeitstage</h4>
                <div>
                {
                    Object.keys(days).map(dayIdx => {
                        return <Weekday key={dayIdx} dayIdx={dayIdx} dayName={days[dayIdx]} />
                    })
                }
                </div>
            </div>
            <div className="weekly-workhours">
                <h4>Wochenarbeitszeit</h4>
                <div className="input-group">
                    <input type="number" value={userConfig.weeklyWorkhours} step="0.5" onChange={({target}) => dispatch(AdminActions.setUserConfig('weeklyWorkhours', parseFloat(target.value)))} /> <label>Stunden</label>
                </div>
            </div>
            <div className="holiday-days">
                <h4>Urlaubstage</h4>
                <div className="input-group">
                    <input type="number" value={userConfig.daysOfHolidayOnA5DayWeek} onChange={({target}) => dispatch(AdminActions.setUserConfig('daysOfHolidayOnA5DayWeek', parseInt(target.value)))} /> <label>Tage bei einer Fünf-Tage-Woche</label>
                </div>
            </div>

            <div className="calculated-values">
                <h4>Arbeitstage / Woche:</h4>
                <div className="value">
                {
                    Math.round(user.daysPerWeek * 100) / 100
                } Tage
                </div>

                <h4>Tägliche Arbeitszeit:</h4>
                <div className="value">
                {
                    Math.floor(user.dailyWorkhours)
                } Stunden

                {
                    (Math.floor(user.dailyWorkhours) !== user.dailyWorkhours)?
                        ' und ' + Math.round(60 * (user.dailyWorkhours - Math.floor(user.dailyWorkhours))) + ' Minuten'
                    :null
                }
                </div>

                <h4>Urlaubstage:</h4>
                <div className="value">
                {
                    Math.round(user.daysOfHoliday * 10) / 10
                } Tage
                </div>

            </div>
            <div className="footer">
                {
                    (dataToStore !== null)?
                        <button className="primary" onClick={(e) => {
                            dispatch(AdminActions.storeUserConfig(firebase, userId, dataToStore));
                        }}>Änderungen speichern</button>
                    :null
                }
            </div>
        </div>
    )
}
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useFirebase} from 'react-redux-firebase';
import * as AdminActions from '../actions/AdminActions';
import {Edit, Configuration, Summary} from '../components/Admin';

export default function MapContainer(props) {
    const admin = useSelector((state) => state.admin.toJS());
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('summary');

    useEffect(() => {
        if(typeof admin.listener !== 'undefined' || admin.listener !== null)
            dispatch(AdminActions.loadUsers(firebase));
    }, [props]);

    if(admin.admin === false)
        return (
            <div>Not allowed!</div>
        )

    if(admin.loading){
        return (
            <div className="loader" />
        )
    }

    let usersSorted = Object.keys(admin.users).sort((a, b) => admin.users[a].displayName.localeCompare(admin.users[b].displayName));

    return (
        <div className="admin">
            <div className="userlist">
            {
                usersSorted.map((userKey) => {
                    return (
                        <a className={(userKey === admin.selected)?'active':null} href="#" key={userKey} onClick={(e) => {
                            dispatch(AdminActions.selectUser(userKey));
                        }}>{admin.users[userKey].displayName}</a>
                    )
                })
            }
            </div>
            {
                (typeof admin.selected !== 'undefined' && admin.selected !== null)?
                <div className="content">
                    <div className="selected-user">{admin.selected}</div>

                    <div className="tabs">
                        <div className="tabbar">
                            <div className={(activeTab === 'summary')?'active':''} onClick={(e) => {
                                setActiveTab('summary');
                            }}>Übersicht</div>
                            <div className={(activeTab === 'calendar')?'active':''} onClick={(e) => {
                                setActiveTab('calendar');
                            }}>Kalender</div>
                            <div className={(activeTab === 'configuration')?'active':''} onClick={(e) => {
                                setActiveTab('configuration');
                            }}>Einstellungen</div>
                        </div>
                        {
                            (activeTab === 'summary')?
                                <div>
                                    <Summary />
                                </div>
                            :null
                        }
                        {
                            (activeTab === 'configuration')?
                                <div>
                                    <Configuration />
                                </div>
                            :null
                        }
                        {
                            (activeTab === 'calendar')?
                                <Edit />
                            :null
                        }
                    </div>
                </div>
                :null
            }
        </div>
    )
}
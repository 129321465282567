import Immutable from 'immutable'
import * as types from '../constants/CalendarActionTypes'

const initialState = Immutable.Map({

})

function getStateOfUser(state, userId){
    if(!state.has(userId))
        state = state.set(userId, Immutable.Map({
            day: Immutable.Map(),
            week: Immutable.Map()
        }));

    return state.get(userId);
}

function setStateOfUser(state, userData, userId){
    return state.set(userId, userData);
}

function updateDay(allUserState, date, key, data, userId){
    var state = getStateOfUser(allUserState, userId);

    if(!state.hasIn(['day', date]))
        state = state.setIn(['day', date], Immutable.Map({
            date: date
        }));
    let day = state.getIn(['day', date]);
    day = day.set(key, Immutable.fromJS(data));
    state = state.setIn(['day', date], day);
    return setStateOfUser(allUserState, state, userId);
}

function setWeek(allUserState, week, userId){
    var state = getStateOfUser(allUserState, userId);
    state = state.set('week', week);
    return setStateOfUser(allUserState, state, userId);
}

export default function calendar (state = initialState, action) {
  switch (action.type) {
    case types.DATE_UPDATED:
        state = updateDay(state, action.date, 'time', action.data, action.userId);
        return updateDay(state, action.date, 'loading', false, action.userId);
    case types.REGISTERED_LISTENER:
        return updateDay(state, action.date, 'listener', action.listener, action.userId);
    case types.SET_DAY_OFF:
        return updateDay(state, action.date, 'day-off', action.data, action.userId);
    case types.SET_PUBLIC_HOLIDAY:
        return updateDay(state, action.date, 'public-holiday', action.data, action.userId);
    case types.SET_WEEK:
        return setWeek(state, action.date, action.userId);
    case types.LOAD_DATE:
        return updateDay(state, action.date, 'loading', true, action.userId);
    default:
      return state
  }
}
import React, {useEffect} from 'react';
import {useFirebase} from 'react-redux-firebase'
import {useSelector, useDispatch} from 'react-redux'
import * as AdminActions from '../../actions/AdminActions'
import moment from 'moment';

export const Weekday = function(props) {
    const dispatch = useDispatch();
    let weekdayConfig = useSelector((state) => {
        let selected = state.admin.get('selected');
        let workdays = state.admin.get('workdays');
        if(typeof workdays !== 'undefined' && workdays !== null && workdays.has(selected)){
            let workdaysOfUser = workdays.get(selected);
            if(workdaysOfUser.has('draft')){
                let draft = workdaysOfUser.get('draft');
                if(draft.has(props.dayIdx)){
                    return draft.get(props.dayIdx).toJS();
                }
            }
        }

        if(state.admin.hasIn(['users', selected, 'workdays', props.dayIdx])){
            return state.admin.getIn(['users', selected, 'workdays', props.dayIdx]).toJS()
        }

        return {
            active: false
        }
    })

    /*const firebase = useFirebase();
    const dispatch = useDispatch();

    const status = useSelector((state) => state.status.get('current').toJS());
    const is_changing = useSelector((state) => state.status.get('is_changing'));

    useEffect(() => {
        dispatch(StatusActions.listenForStatusupdates(firebase, moment().format('YYYY-MM-DD')));
    }, [props]);*/

    const everyNthOptions = ((typeof weekdayConfig === 'undefined' || typeof weekdayConfig.nthWeek === 'undefined')?Array(1):Array(weekdayConfig.nthWeek)).fill(1);

    return (
        <div className={["weekday", weekdayConfig.active?'active':'', weekdayConfig.draft?'draft':''].join(' ')}>
            <div className="toggle">
                <input type="checkbox" value={1} checked={weekdayConfig.active} onChange={(e, status) => {
                    dispatch(AdminActions.setWeekdayConfig(props.dayIdx, {...weekdayConfig, active: !weekdayConfig.active}));
                }} />
            </div>
            <div className="dayname">
            {
                props.dayName
            }
            </div>
            {
                (weekdayConfig.active)?
                    (
                    <div className='multiweek'>
                        <div className="">
                            <input type="checkbox" value={1} checked={(typeof weekdayConfig.multiweek === 'undefined')?false:weekdayConfig.multiweek} onChange={(e, status) => {
                                dispatch(AdminActions.setWeekdayConfig(props.dayIdx, {...weekdayConfig, multiweek: !weekdayConfig.multiweek}));
                            }} /> mehrwöchiger Rhythmus
                        </div>
                        {
                            ((typeof weekdayConfig.multiweek === 'undefined')?false:weekdayConfig.multiweek)?
                                <div className='multiweek-options'>
                                    <div className='nth-week'>
                                        <label>Im</label>
                                        <input type="number" value={(typeof weekdayConfig.nthWeek === 'undefined')?1:weekdayConfig.nthWeek} onChange={({target}) => {
                                            if(target.value > 0)
                                                dispatch(AdminActions.setWeekdayConfig(props.dayIdx, {...weekdayConfig, nthWeek: parseInt(target.value)}));
                                        }} />
                                        <label>Rhythmus</label>
                                    </div>
                                    <div className='starts-on'>
                                        <label>Erstmalig am:</label>
                                        <input type="date" step={7} value={(typeof weekdayConfig.startsOn === 'undefined')?moment().startOf('week').add('days', props.dayIdx).format('YYYY-MM-DD'):moment(weekdayConfig.startsOn).format('YYYY-MM-DD')} onChange={({target}) => {
                                            if(moment(target.value).day() === parseInt(props.dayIdx))
                                                dispatch(AdminActions.setWeekdayConfig(props.dayIdx, {...weekdayConfig, startsOn: moment(target.value).valueOf()}));
                                        }} />
                                    </div>
                                    <div className='every-nth'>
                                        <label>Jede:</label>
                                        {
                                           everyNthOptions.map((val, everyNthIdx) => {
                                                return (<div>
                                                    <input value="1" type="checkbox" checked={
                                                        (typeof weekdayConfig.everyNth === 'undefined')?
                                                            (everyNthIdx === 0)
                                                        :
                                                            (weekdayConfig.everyNth.indexOf(everyNthIdx) !== -1)
                                                    } onChange={(e) => {
                                                        let active = e.target.checked;
                                                        
                                                        let set = [];
                                                        if(typeof weekdayConfig.everyNth !== 'undefined')
                                                            set = [...weekdayConfig.everyNth];
                                                        else
                                                            set = [0];
                                                        
                                                        if(active && set.indexOf(everyNthIdx) === -1)
                                                            set.push(everyNthIdx);
                                                        else if(!active && set.indexOf(everyNthIdx) !== -1)
                                                            set.splice(set.indexOf(everyNthIdx), 1);
                                                        
                                                        dispatch(AdminActions.setWeekdayConfig(props.dayIdx, {...weekdayConfig, everyNth: set}));
                                                    }} /> {everyNthIdx + 1}. Woche
                                                </div>)
                                            })
                                        }
                                    </div>
                                </div>
                            :null
                        }
                    </div>
                    )
                :null
            }
        </div>
    )
}
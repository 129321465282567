import React, {useEffect} from 'react';
import {Status} from '../components/Status';
import {Calendar} from '../components/Calendar';
import {Summary} from '../components/Summary';
import { useFirebase } from 'react-redux-firebase';
const moment = require('moment');

export default function MapContainer(props) {
    const firebase = useFirebase();

    return (
        <div className="userview">
            <Status date={moment().format('YYYY-MM-DD')}/>
            <Calendar userId={firebase.auth().currentUser.email} />
            <Summary />
        </div>
    )
}
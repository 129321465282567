import React, {useEffect, useState} from 'react';
import {useFirebase} from 'react-redux-firebase'
import {useSelector, useDispatch} from 'react-redux'
import * as AdminActions from '../../actions/AdminActions'
import {Calendar} from '../Calendar'
import {Duration} from '../Duration'
import * as DayOfReasons from '../../constants/DayOffReasons';
import moment from 'moment';

export const Edit = function(props) {
    function getDurationParts(duration){
        const negative = duration < 0;

        let minutes = Math.floor(Math.abs(duration) / 60);
        let seconds = Math.abs(duration) - (minutes * 60);

        let hours = Math.floor(minutes / 60);
        minutes = minutes - (hours * 60);

        //minutes = String(minutes).padStart(2, '0');
        //seconds = String(seconds).padStart(2, '0');

        return {
            sign: (negative)?'-':'+',
            hours: hours,
            minutes: minutes,
            seconds: seconds
        };
    }

    function partsToDuration(parts){
        let hours = parseInt(parts.hours) * 60 * 60;
        let minutes = parseInt(parts.minutes) * 60;
        let seconds = parseInt(parts.seconds);

        return ((parts.sign === '-')?-1:1) * (hours + minutes + seconds)
    }

    const dispatch = useDispatch();
    const firebase = useFirebase();

    const [correctionTime, setCorrectionTime] = useState(0);
    const [reason, setReason] = useState("");

    const days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    const admin = useSelector((state) => state.admin.toJS());

    const user = useSelector((state) => {
        let selected = state.admin.get('selected');
        if(typeof selected === 'undefined' || selected === null)return null;

        let user = {
            daysPerWeek: 0,
            dailyWorkhours: 0,
            daysOfHoliday: 0
        };
        
        if(state.admin.hasIn(['users', selected]))
            user = {...user, ...state.admin.getIn(['users', selected]).toJS()};

        return user;
    });

    const day = useSelector((state)=> {
        let selected = state.admin.get('selected');
        if(typeof selected === 'undefined' || selected === null)return null;

        if(!state.calendar.has(selected))return null;
        if(typeof user['edit-day'] === 'undefined' || user['edit-day'] === null)return null;

        let dateStr = moment(user['edit-day'].date).format('YYYY-MM-DD');

        if(!state.calendar.hasIn([selected, 'day', dateStr]))return null;

        return state.calendar.getIn([selected, 'day', dateStr]).toJS();

    });

    const userId = useSelector((state) => state.admin.get('selected'));
    moment.locale('de');

    const correctionTimeParts = getDurationParts(correctionTime);

    return (
        <div className="calender-container">
            <Calendar userId={userId} admin={true} selected={(typeof user['edit-day'] !== 'undefined')?user['edit-day'].date:null} />
            {
                (typeof user['edit-day'] !== 'undefined')?
                <div className="edit-day">
                    <div className="header">{moment(user['edit-day'].date).format('dddd, DD.MM.YYYY')}</div>

                    {
                        (user['edit-day'].loading)?
                            <div className="content"><div className="loader dark" /></div>
                        :
                            <div className="content">
                                <div className="event-list">
                                {
                                    (typeof user['edit-day'].events !== 'undefined')?
                                        user['edit-day'].events.map((event) => {
                                            switch(event.type){
                                                case 'set_target_time':
                                                    return (<div className="set-target-time"><div className="timestamp">{moment(event.timestamp).format('DD.MM.YYYY HH:mm:ss')}</div>
                                                        Soll-Arbeitszeit von <Duration duration={event.duration} /> Std. gesetzt.
                                                    </div>)
                                                case 'switch_status':
                                                    return (<div className={['switch-status', 'switch-to-' + event.new_status].join(' ')}><div className="timestamp">{moment(event.timestamp).format('DD.MM.YYYY HH:mm:ss')}</div>
                                                        {
                                                            (event.new_status === 'present')?
                                                                <div>Status auf anwesend geändert</div>
                                                            :
                                                                <div>
                                                                    Status nach {
                                                                        (typeof event.duration !== 'undefined')?
                                                                            <Duration duration={event.duration} />
                                                                        :null
                                                                    } auf abwesend geändert.
                                                                </div>
                                                        }
                                                    </div>)
                                                case 'set_correction_time':
                                                    return (<div className="set-correction-time"><div className="timestamp">{moment(event.timestamp).format('DD.MM.YYYY HH:mm:ss')}</div>
                                                        Zeit ergänzt <Duration duration={event.duration} /> Std. Grund: {event.reason}.
                                                    </div>)
                                                default:
                                                    return <div>
                                                        {event.type}
                                                    </div>
                                            }
                                        })
                                    :null
                                }
                                </div>

                                <div className="set-day-off">
                                    <div className="caption">Arbeitsfreier Tag:</div>

                                    <div className="input-container">
                                        <select onChange={(e) => {
                                            let date = moment(user['edit-day'].date).format('YYYY-MM-DD');

                                            if(e.target.value !== '')
                                                dispatch(AdminActions.setDayOff(firebase, date, userId, e.target.value));
                                            else
                                                dispatch(AdminActions.removeDayOff(firebase, date, userId));
                                        }}>
                                            <option value="">Nicht arbeitsfrei</option>
                                            {
                                                Object.keys(DayOfReasons).map(val => <option key={val} value={val} selected={
                                                    (day !== null && typeof day['day-off'] !== 'undefined' && day['day-off'].reason === val)
                                                }>{DayOfReasons[val]}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="add-time">
                                    <div className="caption">Zeit ergänzen:</div>
                                    
                                    <div className="input-container">
                                        <div className="sign" onClick={(e) => {
                                            setCorrectionTime(partsToDuration({
                                                ...correctionTimeParts,
                                                sign: (correctionTimeParts.sign === '-')?'+':'-'
                                            }));
                                        }}>{correctionTimeParts.sign}</div>
                                        <div className="hours">
                                            <input min="0" type="number" value={correctionTimeParts.hours} onChange={(e) => {
                                                if(parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 100)
                                                    setCorrectionTime(partsToDuration({
                                                        ...correctionTimeParts,
                                                        hours: e.target.value
                                                    }));
                                            }} />
                                        </div>
                                        <div className="label">
                                            Std
                                        </div>
                                        <div className="minutes">
                                            <input min="0" max="60" type="number" value={correctionTimeParts.minutes} onChange={(e) => {
                                                if(parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 60)
                                                    setCorrectionTime(partsToDuration({
                                                        ...correctionTimeParts,
                                                        minutes: e.target.value
                                                    }));
                                            }} />
                                        </div>
                                        <div className="label">
                                            Min
                                        </div>
                                        <div className="seconds">
                                            <input min="0" max="60" type="number" value={correctionTimeParts.seconds} onChange={(e) => {
                                                if(parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 60)
                                                    setCorrectionTime(partsToDuration({
                                                        ...correctionTimeParts,
                                                        seconds: e.target.value
                                                    }));
                                            }} />
                                        </div>
                                        <div className="label last-label">
                                            Sec
                                        </div>
                                    </div>
                                     <div class="reason">
                                        Grund:
                                        <input value={reason} onChange={(e) => setReason(e.target.value)} />
                                    </div>
                                    <div className={["btn", (reason === "")?'disabled':''].join(' ')} onClick={(e) => {
                                        if(reason !== "")
                                            dispatch(AdminActions.setCorrectionTime(firebase, moment(user['edit-day'].date).format('YYYY-MM-DD'), userId, correctionTime, reason));
                                    }}>
                                        <a>Speichern</a>
                                    </div>
                                </div>

                            </div>
                    }
                </div>
                :null
            }
        </div>
    )
}
import * as types from '../constants/RouterActionTypes'

export const routerMiddleware = (history) => () => (next) => (action) => {
  switch (action.type) {
    case types.PUSH:
      history.push(action.payload);
      break;
    case types.REPLACE:
      history.replace(action.payload);
      break;
    case types.GO:
      history.go(action.payload);
      break;
    case types.GO_BACK:
      history.goBack();
      break;
    case types.GO_FORWARD:
      history.goForward();
      break;
    default:
      return next(action);
  }
};
import Immutable from 'immutable'
import * as types from '../constants/ExampleActionTypes'

const initialState = Immutable.Map({
	message: null
})

function sayHello(state){
	return state.set('message', 'World!');
}

export default function example (state = initialState, action) {
  switch (action.type) {
    case types.SAY_HELLO:
      return sayHello(state)
    default:
      return state
  }
}
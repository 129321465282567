import Immutable from 'immutable'
import * as types from '../constants/UserActionTypes'

const initialState = Immutable.Map({
    loading: false
})

/*function start_auth(state){
	return state.set('state', 'select_provider');
}

function start_login_with_provider(state){
    return state.set('loading', true);
}

function start_session_restore(state){
    state = state.set('loading', true);
    return state.set('session', 'checking');
}

function finish_session_restore(state){
    state = state.set('loading', false);
    return state.set('session', 'done');
}*/

function startLoadingDetails(state){
    return state.set('loading', true);
}

function doneLoadingDetails(state, details){
    state = state.set('loading', false);
    for(var key in details){
        state = state.set(key, Immutable.fromJS(details[key]));
    }
    return state;
}

export default function user (state = initialState, action) {
  switch (action.type) {
    case types.START_LOADING_DETAILS:
        return startLoadingDetails(state);
    case types.DONE_LOADING_DETAILS:
        return doneLoadingDetails(state, action.data);
    /*case types.START_AUTH:
      return start_auth(state)
    case types.START_LOGIN_WITH_PROVIDER:
        return start_login_with_provider(state);
    case types.START_SESSION_RESTORE:
        return start_session_restore(state);
    case types.FINISH_SESSION_RESTORE:
        return finish_session_restore(state);*/
    default:
      return state
  }
}
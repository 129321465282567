import Immutable from 'immutable'
import * as types from '../constants/RequestsActionTypes'

const initialState = Immutable.Map({
	requests: Immutable.Map({
        all: Immutable.Map()
    }),
    listener: null
})

function add_listener(state, listener){
    return state.set('listener', listener);
}

function update(state, requests){
    if(typeof requests !== 'undefined' && requests !== null){
        for(var treeId in requests){
            let tree = requests[treeId];

            if(state.hasIn(['requests', 'all'], treeId)){
                let status = state.getIn(['requests', 'all', treeId], 'status');

                if(status !== tree.status)
                    state = state.deleteIn(['requests', status, treeId]);
            }

            state = state.setIn(['requests', 'all', treeId], Immutable.fromJS(tree));
            if(!state.hasIn(['requests', tree.status]))
                state = state.setIn(['requets', tree.status], Immutable.Map());
            
            state = state.setIn(['requets', tree.status, treeId], tree);
        }
    }

    return state;
}

export default function requests (state = initialState, action) {
  switch (action.type) {
    case types.ADD_LISTENER:
        return add_listener(state, action.listener);
    case types.UPDATE:
        return update(state, action.requests);
    default:
      return state
  }
}
import React, { Component, useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import configureStore from '../store/configureStore'
import { createBrowserHistory } from 'history'
import { startListener } from '../utils/routerListener'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import "firebase/storage"
import 'firebase/functions'

import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore
import { useSelector, useDispatch } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

//import { default as Login } from './LoginApp.js'
import {env} from '../constants/Environment';
import * as UserActions from '../actions/UserActions';

const history = createBrowserHistory()
const store = configureStore(null, history)

import { FirebaseError } from '@firebase/util'

startListener(history, store)

let unsubscribe = store.subscribe(() => {
  //let previousLocation = currentLocation
  let currentLocation = store.getState().router.pathname
  /*if (previousLocation !== currentLocation) {
    // You can render your application reactively here!
  }*/
})

const fbConfig = {
  apiKey: "AIzaSyBPOLv9OVr7za_09sezK40PigKkAKDc3DQ",
  authDomain: "time-clock-kisad.firebaseapp.com",
  databaseURL: "https://time-clock-kisad.firebaseio.com",
  projectId: "time-clock-kisad",
  storageBucket: "time-clock-kisad.appspot.com",
  messagingSenderId: "71344300358",
  appId: "1:71344300358:web:bc375ffb74a98796320547",
  measurementId: "G-F9N9MFBC4D"
}

// react-redux-firebase config
const rrfConfig = {
  //userProfile: 'environments/' + env + '/users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

let fireApp = firebase.initializeApp(fbConfig)
firebase.firestore();
fireApp.functions('europe-west1');
//firebase.functions(firebaseApp);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  const dispatch = useDispatch();

  useEffect(() => {
      if(!isLoaded(auth))return;
      if(isEmpty(auth))return;

      dispatch(UserActions.loadUserDetails(firebase, auth.email));
  }, [isLoaded(auth), isEmpty(auth)])

  if (!isLoaded(auth))return null;
  if(isEmpty(auth)){
        firebase.login({
            provider: 'google',
            type: 'redirect'
        })
        return null;
  }
  return children
}

export default class App extends Component {
  render () {
    return (
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <div>
                    <div>
                        <AuthIsLoaded>
                        {React.cloneElement(this.props.children)}            
                        </AuthIsLoaded>
                    </div>
                </div>
            </ReactReduxFirebaseProvider>
        </Provider>
    )
  }
}
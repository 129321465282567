import React, {useEffect} from 'react';
import {useFirebase} from 'react-redux-firebase'
import {useSelector, useDispatch} from 'react-redux'
import * as AdminActions from '../../actions/AdminActions'
import moment from 'moment';
import {Duration} from '../Duration';

export const Summary = function(props) {
    const dispatch = useDispatch();
    const selected = useSelector((state) => state.admin.get('selected'));
    const firebase = useFirebase();

    const summary = useSelector((state) => {
        if(!state.admin.hasIn(['summary', selected]))return null;

        return state.admin.getIn(['summary', selected]).toJS();
    })

    useEffect(() => {
        dispatch(AdminActions.loadSummary(firebase, selected));
    }, [selected]);

    let timeTotal = 0;
    if(summary !== null && typeof summary.time !== 'undefined'){
        if(typeof summary.time.duration !== 'undefined')
            timeTotal += summary.time.duration;
        if(summary !== null && typeof summary.time.carry !== 'undefined')
            timeTotal += summary.time.carry;
        if(summary !== null && typeof summary.time.target_time !== 'undefined')
            timeTotal += summary.time.target_time;
    }

    return (
        <div className='summary'>
        {
            (summary === null || summary.loading)?
                <div className="loader dark" />
            :
            (
                <div>
                    <h3>Zeit</h3>
                    <div className="time">
                        <div>
                            <div className="caption">Gesamtzeit</div>
                            <div className="value"><Duration duration={timeTotal} /></div>
                        </div>
                    </div>

                    <h3>Urlaub</h3>
                    {
                        summary.holidays.map((holiday) => {
                            return (<div className="holiday" key={holiday.year}>
                                <h4>{holiday.year}</h4>

                                <div>
                                    <div className="caption">Urlaubstage in diesem Jahr</div>
                                    <div className="value">{holiday.total}</div>
                                </div>
                                <div>
                                    <div className="caption">…davon aus dem Vorjahr</div>
                                    <div className="value">{(typeof holiday.carry === 'undefined')?0:holiday.carry}</div>
                                </div>
                                <div>
                                    <div className="caption">Geplante Urlaubstage</div>
                                    <div className="value">{holiday.planned}</div>
                                </div>
                                <div>
                                    <div className="caption">Bereits angetretene Urlaubstage</div>
                                    <div className="value">{holiday.used}</div>
                                </div>
                                <div>
                                    <div className="caption">Noch nicht geplante Urlaubstage</div>
                                    <div className="value">{holiday.total - holiday.planned}</div>
                                </div>
                            </div>)
                        })
                    }
                </div>
            )
        }
        </div>
    )
}
import * as types from '../constants/CalendarActionTypes'

export function setWeek(date, userId){
    return {
        type: types.SET_WEEK,
        userId: userId,
        date: date
    }
}

export function loadDate(firebase, date, userId){
	return function(dispatch){
        dispatch({
            type: types.LOAD_DATE,
            userId: userId,
            date: date
        });

        return (new Promise((resolve, reject) => {				
				(async () => {
                    let firestore = firebase.firestore();

                    let listener = await firestore.collection('users').doc(userId).collection('by-date').doc(date).onSnapshot((document) => {
                        if(document.exists)
                            dispatch({
                                type: types.DATE_UPDATED,
                                userId: userId,
                                date: date,
                                data: document.data()
                            });
                        else
                            dispatch({
                                type: types.DATE_UPDATED,
                                userId: userId,
                                date: date,
                                data: {}
                            })
                    });

                    dispatch({
                        type: types.REGISTERED_LISTENER,
                        userId: userId,
                        date: date,
                        listener: listener
                    });

                    await firestore.collection('users').doc(userId).collection('days-off').doc(date).onSnapshot((dayOff) => {
                        if(dayOff.exists){
                            dispatch({
                                type: types.SET_DAY_OFF,
                                userId: userId,
                                date: date,
                                data: dayOff.data()
                            })
                        };
                    });

                    let publicHoliday = await firestore.collection('public-holidays').doc(date).get();
                    if(publicHoliday.exists){
                        dispatch({
                            type: types.SET_PUBLIC_HOLIDAY,
                            userId: userId,
                            date: date,
                            data: publicHoliday.data()
                        })
                    }

					resolve();
				})();
			}));
	}
}
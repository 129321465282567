import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers';
import { combineReducers } from 'redux';  
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from '../middleware/RouterMiddleware.js'

import * as reducers from '../reducers';

const reducer = combineReducers(reducers); 

const enhancer = compose(
  // Middleware you want to use in development:
  applyMiddleware(thunkMiddleware)
);

export default function configureStore(initialState, history) {
	const enhancer2 = compose(applyMiddleware(routerMiddleware(history)), enhancer);
		
  // Note: only Redux >= 3.1.0 supports passing enhancer as third argument.
  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  const store = createStore(reducer, (initialState === null)?undefined:initialState, enhancer2);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot) {
    module.hot.accept('../reducers', () =>
      store.replaceReducer(require('../reducers')/*.default if you use Babel 6+ */)
    );
  }

  return store;
}
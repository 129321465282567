import Immutable from 'immutable'
import * as types from '../constants/StatusActionTypes'

const initialState = Immutable.Map({
    is_changing: false,
    current: Immutable.Map({
        '_dirty': true
    }),
	events: Immutable.Map(),
    summary: Immutable.Map(),
    userDetails: null,
    holidays: null
})

function statusUpdated(state, status){
    for(var key in status)
        state = state.setIn(['current', key], Immutable.fromJS(status[key]));
    state = state.setIn(['current', '_dirty'], false);
    return state;
}

function setEvents(state, date, events){
    state = state.setIn(['events', date], Immutable.fromJS(events));
    return state;
}

function startChangeStatus(state){
    state = state.setIn(['current', '_dirty'], true);
    return state.set('is_changing', true);
}

function doneChangeStatus(state){
    return state.set('is_changing', false);
}

function setSummary(state, date, summary){
    state = state.setIn(['summary', date], Immutable.fromJS(summary));
    return state;
}

function setUserDetails(state, data){
    state = state.set('userDetails', Immutable.fromJS(data));
    return state;
}

function setHolidays(state, data){
    state = state.set('holidays', Immutable.fromJS(data));
    return state;
}

export default function status (state = initialState, action) {
  switch (action.type) {
    case types.STATUS_UPDATED:
      return statusUpdated(state, action.status)
    case types.SET_EVENTS:
        return setEvents(state, action.date, action.events);
    case types.START_CHANGE_STATUS:
        return startChangeStatus(state);
    case types.DONE_CHANGE_STATUS:
        return doneChangeStatus(state);
    case types.SET_SUMMARY:
        return setSummary(state, action.date, action.summary);
    case types.SET_USER_DETAILS:
        return setUserDetails(state, action.data);
    case types.SET_HOLIDAYS_SUMMARY:
        return setHolidays(state, action.data);
    default:
      return state
  }
}
export const REQUEST_USERS = 'ADMIN/REQUEST_USERS';
export const RECEIVED_USERS = 'ADMIN/RECEIVED_USERS';
export const NOT_ALLOWED = 'ADMIN/NOT_ALLOWED';
export const USER_SELECTED = 'ADMIN/USER_SELECTED';
export const SET_WEEKDAY_CONFIG = 'ADMIN/SET_WEEKDAY_CONFIG';
export const SET_USER_CONFIG = 'ADMIN/SET_USER_CONFIG';
export const START_STORE_USER_CONFIG = 'ADMIN/START_STORE_USER_CONFIG';
export const DONE_STORE_USER_CONFIG = 'ADMIN/DONE_STORE_USER_CONFIG';
export const DONE_REGISTER_USER_LISTENER = 'ADMIN/DONE_REGISTER_USER_LISTENER';
export const START_EDIT_DAY = 'ADMIN/START_EDIT_DAY';
export const RECEIVED_EVENTS = 'ADMIN/RECEIVED_EVENTS';
export const START_SET_CORRECTION_TIME = 'ADMIN/START_SET_CORRECTION_TIME';
export const DONE_SET_CORRECTION_TIME = 'ADMIN/DONE_SET_CORRECTION_TIME';
export const START_SET_DAY_OFF = 'ADMIN/START_SET_DAY_OFF';
export const DONE_SET_DAY_OFF = 'ADMIN/DONE_SET_DAY_OFF';
export const DONE_LOADING_SUMMARY = 'ADMIN/DONE_LOADING_SUMMARY';
export const START_LOADING_SUMMARY = 'ADMIN/START_LOADING_SUMMARY';
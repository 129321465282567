import * as types from '../constants/RouterActionTypes'
import {__PREFIX__} from '../constants/UrlPrefix'

export const push = (href) => {
	return {
	  type: types.PUSH,
	  payload: (href.substr(0, ( __PREFIX__ ).length) == __PREFIX__)?href:( __PREFIX__ ) + href.replace(/^\//, ''),
	}
};

export const replace = (href) => ({
  type: types.REPLACE,
  payload: href,
});

export const go = (index) => ({
  type: types.GO,
  payload: index,
});

export const goBack = () => ({
  type: types.GO_BACK,
});

export const goForward = () => ({
  type: types.GO_FORWARD,
});

export const locationChange = ({ pathname, search, hash }) => ({
  type: types.LOCATION_CHANGE,
  payload: {
    pathname,
    search,
    hash,
  },
});
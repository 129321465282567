import React, {useEffect} from 'react';
import {useFirebase} from 'react-redux-firebase'
import {useSelector, useDispatch} from 'react-redux'
import * as StatusActions from '../actions/StatusActions'

export const Summary = function(props) {
    const firebase = useFirebase();
    const dispatch = useDispatch();

    const holiday = useSelector((state) => (state.status.get('holidays') === null)?null:state.status.get('holidays').toJS());
    const userDetails = useSelector((state) => (state.status.get('userDetails') === null)?null:state.status.get('userDetails').toJS());
    
    return (
        <div className="summary">
            {
                (userDetails === null)?
                    null
                :
                    <div className="user-details">
                        <div>
                            <div>
                                <div className="caption">Arbeistage / Woche</div>
                                <div className="value">{Math.round(userDetails.daysPerWeek * 100) / 100}</div>
                            </div>
                            <div>
                                <div className="caption">Stunden / Tag</div>
                                <div className="value">{Math.round(userDetails.dailyWorkhours * 100) / 100}</div>
                            </div>
                            <div>
                                <div className="caption">Urlaubstage / Jahr</div>
                                <div className="value">{Math.round(userDetails.daysOfHoliday * 2) / 2}</div>
                            </div>
                        </div>
                        <a href="https://chrome.google.com/webstore/detail/kisad-zeiterfassung/hpmmghjkafjmplfidbnlollhjlfegcdp/related?hl=de&authuser=0">Browsererweiterung installieren</a>
                    </div>
            }

            {
                (holiday === null)?
                    null
                :
                    <div className="holidays">
                        <div>
                            <div className="caption">Urlaubstage in diesem Jahr</div>
                            <div className="value">{holiday.total}</div>
                        </div>
                        <div>
                            <div className="caption">…davon aus dem Vorjahr</div>
                            <div className="value">{(typeof holiday.carry === 'undefined')?0:holiday.carry}</div>
                        </div>
                        <div>
                            <div className="caption">Geplante Urlaubstage</div>
                            <div className="value">{holiday.planned}</div>
                        </div>
                        <div>
                            <div className="caption">Bereits angetretene Urlaubstage</div>
                            <div className="value">{holiday.used}</div>
                        </div>
                        <div>
                            <div className="caption">Noch nicht geplante Urlaubstage</div>
                            <div className="value">{holiday.total - holiday.planned}</div>
                        </div>
                    </div>
            }
        </div>
    )
}
import Immutable from 'immutable'
import * as types from '../constants/AdminActionTypes'

const initialState = Immutable.Map({
    users: Immutable.Map(),
    loading: false,
    admin: true,
    workdays: Immutable.Map(),
    listener: null,
    summary: Immutable.Map()
})

function requestUsers(state){
    return state.set('loading', true);
}

function receivedUsers(state, users){
    state = state.set('loading', false);

    for(var userId in users){
        if(!state.hasIn(['users', userId]))
            state = state.setIn(['users', userId], Immutable.fromJS(users[userId]));
        else {
            for(var key in users[userId]){
                state = state.setIn(['users', userId, key], Immutable.fromJS(users[userId][key]));
            }

            if(state.hasIn(['users', userId, '_dirty']))
                state = state.deleteIn(['users', userId, '_dirty']);
        }
    }

    return state;
}

function notAllowed(state){
    return state.set('admin', false);
}

function userSelected(state, userId){
    return state.set('selected', userId);
}

function setWeekdayConfig(state, day, config){
    if(!state.has('selected'))return state;
    let selected = state.get('selected');

    if(!state.hasIn(['workdays', selected]))
        state = state.setIn(['workdays', selected], Immutable.Map());
    
    let workdaysOfUser = state.getIn(['workdays', selected]);
    if(!workdaysOfUser.has('draft'))
        workdaysOfUser = workdaysOfUser.set('draft', Immutable.Map());

    workdaysOfUser = workdaysOfUser.setIn(['draft', day], Immutable.fromJS(config));
    workdaysOfUser = workdaysOfUser.setIn(['draft', day, 'draft'], true);

    state = state.setIn(['workdays', selected], workdaysOfUser);
    state = calculateDays(state);
    return state;
}

function setUserConfig(state, key, value){
    if(!state.has('selected'))return state;
    let selected = state.get('selected');

    let user = state.getIn(['users', selected]);
    if(!user.has('configDraft'))
        user = user.set('configDraft', Immutable.Map());

    user = user.setIn(['configDraft', key], value);
    state = state.setIn(['users', selected], user);
    state = calculateDays(state);
    return state;
}

function calculateDays(state){
    if(!state.has('selected'))return state;
    let selected = state.get('selected');

    let user = state.getIn(['users', selected]);
    if(!user.has('draft'))
        user = user.set('draft', Immutable.Map());
    
    var days = [];

    for(var i = 0;i < 7; i++){
        days[i] = {
            active: false
        };

        if(state.hasIn(['users', selected, 'workdays', String(i)])){
            days[i] = state.getIn(['users', selected, 'workdays', String(i)]).toJS();
        }

        if(state.hasIn(['workdays', selected, 'draft', String(i)]))
            days[i] = state.getIn(['workdays', selected, 'draft', String(i)]).toJS();
    }
   
    let daysPerWeek = 0;

    for(var day of days){
        if(!day.active)continue;

        if(typeof day.multiweek === 'undefined' || day.multiweek === null || !day.multiweek){
            daysPerWeek++;
        }else{
            let nthWeek = (typeof day.nthWeek !== 'undefined' && day.nthWeek !== null)?day.nthWeek:1;

            let everyNth = (typeof day.everyNth !== 'undefined' && day.everyNth !== null)?day.everyNth:[0];

            daysPerWeek += everyNth.length / nthWeek;
        }
    }

    let weeklyWorkhours = 40;
    if(user.hasIn(['config', 'weeklyWorkhours']))
        weeklyWorkhours = user.getIn(['config', 'weeklyWorkhours']);
    
    if(user.hasIn(['configDraft', 'weeklyWorkhours']))
        weeklyWorkhours = user.getIn(['configDraft', 'weeklyWorkhours']);

    let dailyWorkhours = (daysPerWeek === 0)?0:(weeklyWorkhours / daysPerWeek);

    let daysOfHolidayOnA5DayWeek = 30;
    if(user.hasIn(['config', 'daysOfHolidayOnA5DayWeek']))
        daysOfHolidayOnA5DayWeek = user.getIn(['config', 'daysOfHolidayOnA5DayWeek']);
    
    if(user.hasIn(['configDraft', 'daysOfHolidayOnA5DayWeek']))
        daysOfHolidayOnA5DayWeek = user.getIn(['configDraft', 'daysOfHolidayOnA5DayWeek']);

    let daysOfHoliday = (daysPerWeek === 0)?0:daysOfHolidayOnA5DayWeek / 5 * daysPerWeek;

    user = user.setIn(['draft', 'daysPerWeek'], daysPerWeek);
    user = user.setIn(['draft', 'dailyWorkhours'], dailyWorkhours);
    user = user.setIn(['draft', 'daysOfHoliday'], daysOfHoliday);

    state = state.setIn(['users', selected], user);

    return state;
}

function doneStoreUserConfig(state){
    if(!state.has('selected'))return state;
    let selected = state.get('selected');

    if(state.hasIn(['users', selected, 'draft']))
        state = state.deleteIn(['users', selected, 'draft']);
    
    if(state.hasIn(['users', selected, 'configDraft']))
        state = state.deleteIn(['users', selected, 'configDraft']);

    if(state.hasIn(['workdays', selected, 'draft']))
        state = state.deleteIn(['workdays', selected, 'draft']);

    return state;
}

function startStoreUserConfig(state){
    if(!state.has('selected'))return state;

    state = state.setIn(['users', state.get('selected'), '_dirty'], true);
    return state;
}

function startEditDay(state, userId, date){
    if(!state.hasIn(['users', userId]))return state;

    state = state.setIn(['users', userId, 'edit-day'], Immutable.Map({
        date: date,
        loading: true
    }));

    return state;
}

function receiveEvents(state, userId, date, events){
    events.sort((a, b) => a.timestamp - b.timestamp);

    state = state.setIn(['users', userId, 'edit-day', 'events'], Immutable.fromJS(events));
    state = state.setIn(['users', userId, 'edit-day', 'loading'], false);
    return state;
}

function startModifyDay(state, userId, date){
    if(!state.hasIn(['users', userId]))return state;

    state = state.deleteIn(['users', userId, 'edit-day']);

    return state;
}

function startLoadingSummary(state, userId){
    state = state.setIn(['summary', userId, 'loading'], true);
    return state;
}

function doneLoadingSummary(state, userId, holidays, time){
    state = state.setIn(['summary', userId, 'loading'], false);
    state = state.setIn(['summary', userId, 'holidays'], Immutable.fromJS(holidays));
    state = state.setIn(['summary', userId, 'time'], Immutable.fromJS(time));
    return state;
}

export default function status (state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_USERS:
      return requestUsers(state);
    case types.RECEIVED_USERS:
        return receivedUsers(state, action.users);
    case types.NOT_ALLOWED:
        return notAllowed(state);
    case types.USER_SELECTED:
        return userSelected(state, action.userId);
    case types.SET_WEEKDAY_CONFIG:
        return setWeekdayConfig(state, action.day, action.config);
    case types.SET_USER_CONFIG:
        return setUserConfig(state, action.key, action.value);
    case types.START_STORE_USER_CONFIG:
        return startStoreUserConfig(state);
    case types.DONE_STORE_USER_CONFIG:
        return doneStoreUserConfig(state);
    case types.DONE_REGISTER_USER_LISTENER:
        return state.set('listener', action.listener);
    case types.START_EDIT_DAY:
        return startEditDay(state, action.userId, action.date);
    case types.RECEIVED_EVENTS:
        return receiveEvents(state, action.userId, action.date, action.events);
    case types.START_SET_DAY_OFF:
        return startModifyDay(state, action.userId, action.date);
    case types.START_SET_CORRECTION_TIME:
        return startModifyDay(state, action.userId, action.date);
    case types.START_LOADING_SUMMARY:
        return startLoadingSummary(state, action.userId);
    case types.DONE_LOADING_SUMMARY:
        return doneLoadingSummary(state, action.userId, action.holidays, action.time);
    default:
      return state
  }
}